import React from "react"
import Layout from "../components/layout"
import resourceData from "../../data/resources/resourceData.json"
import * as styles from "./members.module.css"
import Meta from "../components/meta.js"
import Footer from "../components/footer.js"

export default function Resources() {
  return (
    <div>
      <Layout>
        <Meta />
        <title>
          Learning resources at CORE
        </title>
        <h1>
          <mark
            style={{ fontWeight: `700`, fontSize: `3rem`, marginTop: `2rem` }}
          >
            Resources
          </mark>
        </h1>
        <div
          className={styles.container}
          style={{
            alignItems: `stretch`,
          }}
        >
          {resourceData.map(event => (
            <div className={styles.card}>
              {/*<img className={styles.image} src={member.avatar} alt={member.name} />*/}
              <div className={styles.textContainer}>
                <h1 className={styles.name}>{event.name}</h1>
                <br></br>
                <p className={styles.desc}>{event.description2}</p>
                <p className={styles.desc}>
                  <span style={{ fontWeight: `600` }}>Contact: </span>
                  {event.contact}
                  </p>
                <a className={styles.link} target="_blank" href={event.link}> <mark>Learn More</mark></a>  
                  

              </div>
            </div>
          ))}
        </div>
      </Layout>
      <Footer></Footer>
    </div>
  )
}

